#headerContainerWrapper {
    height: 38px;
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 40px 1fr;
    grid-template-rows: 38px auto;
    grid-template-areas:
      "leftHeader header rightHeader"
}

.fullHeight {
    height: 100%
}
.fullWidth {
    width: 100%
}

#header {
    grid-area: header;
    /* background-color: var(--white); */
}

#leftHeader {
    grid-area: leftHeader;
    /* background-color: var(--white); */
}

/* Hide scrollbar */
#leftHeader::-webkit-scrollbar {
    display: none;
}

#rightHeader {
    grid-area: rightHeader;
    /* background-color: var(--white); */
}

#rightHeader::-webkit-scrollbar {
    display: none;
}
