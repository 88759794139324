:root {
  --black:  black;
  --white: white;
  --grey: rgb(235, 235, 235); 
  --blue: rgb(45, 111, 207);
  --rightEditorColors: rgba(100,200,100, 0.3);
  --leftEditorColors: rgba(247, 17, 17, 0.3);
  --leftScrollColors: rgba(247, 17, 17, 0.8);
  --rightScrollColors: rgba(100,200,100, 0.8);
  --move: rgba(153, 113, 248, 0.3);
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

body { background:var(--white); }

#leftHeader {
  z-index: 5;
}

#rightHeader {
  z-index: 5;
}

.fullHeight {
  height: 100%
}
.fullWidth {
  width: 100%
}

.buttonBorder {
  border: none;
}

#DropdownButton {
  background: var(--white);
  border: none;
}

.gutterLine {
  text-align: center;
  font-size: 75%;
}

.highlightLineRightEditor {
  position:absolute;
  background:var(--rightEditorColors);
  z-index:20
}

.highlightRangeRightEditor {
   position:absolute;
   background:var(--rightEditorColors);
   z-index:20
}

.highlightLineLeftEditor {
  position:absolute;
  background:var(--leftEditorColors);
  z-index:20
}

.highlightRangeLeftEditor {
  position:absolute;
  background:var(--leftEditorColors);
  z-index:20
}

.leftTextRegionHighlight {
  position: absolute;
  opacity: 0.3;
  z-index: 20;
  pointer-events: auto;
  background:var(--leftEditorColors);
}

.leftDeletedRegionHighlight {
  position: absolute;
  opacity: 0.3;
  z-index: 20;
  pointer-events: auto;
  background:var(--leftEditorColors);
}

.leftLineMoveHighlight {
  position: absolute;
  opacity: 0.3;
  z-index: 20;
  pointer-events: auto;
  background: var(--move);
}

.rightAddedRegionHighlight {
  position: absolute;
  opacity: 0.3;
  z-index: 20;
  pointer-events: auto;
  background:var(--rightEditorColors);
}

.rightTextRegionHighlight {
  position: absolute;
  opacity: 0.3;
  z-index: 20;
  pointer-events: auto;
  background:var(--rightEditorColors);
}

.rightLineMoveHighlight {
  position: absolute;
  opacity: 0.3;
  z-index: 20;
  pointer-events: auto;
  background: var(--move);
}

.RefactorToken {
  position:absolute;
  color: white;
}

.diffConnector {
  fill: rgb(216, 242, 255);
  stroke: rgb(173, 219, 241);
  pointer-events: auto;
}

#reactContainer {
  height: 100%;
}

#mainPage {
  height: 100%;
  width: 100%;
  display: grid;
  grid-template-rows: 38px auto 1px;
  grid-template-areas:
    "reactContainer"
    "containerWrapper"
    "footer"
}

#containerWrapper {
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-columns: 1fr 40px 1fr;
  /* grid-template-rows: 38px auto; */
  grid-template-areas:
    "leftEditor gutter rightEditor"
}

#leftEditor {
  grid-area: leftEditor;
  height: 100%;
}

#gutter {
  grid-area: gutter;
  /* border-left: 1px solid #0E0E0E;
  border-right: 1px solid #0E0E0E; */
  background-color: var(--grey);
  overflow: hidden;
  height: 100%;
}

#rightEditor {
  grid-area: rightEditor;
  height: 100%;
}

/* Ace scroll bar customization*/
.ace_scrollbar {
	overflow: hidden;
}

.ace_scroll-v {
	z-index: 1000;
	position: absolute;
	top: 0;
	right: 0;
	bottom: 12px;
	width: 12px;
	background: whitesmoke
}

.ace_thumb-v {
	background: gainsboro;
}

.ace_scroll-h {
	z-index: 1000;
	position: absolute;
	left: 0;
	right: 0;
	bottom: 0;
	height: 12px;
	background: whitesmoke
}

.ace_thumb-h {
	background: gainsboro
}

.ace_map-row {
	z-index: 1001;
	position: absolute;
	pointer-events: none;
	top: 0;
	right: 0;
	width: 12px;
	height: 4px;
	background: grey;
}

.ace_map-item-left {
	z-index: 1002;
	position: absolute;
	pointer-events: none;
	right: 4px;
	width: 12px;
	height: 16px;
	background: var(--leftScrollColors)
}

.ace_map-item-right {
	z-index: 1002;
	position: absolute;
	pointer-events: none;
	right: 4px;
	width: 12px;
	height: 16px;
	background: var(--rightScrollColors)
}